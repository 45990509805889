import { PrivateDefaultTemplate } from '@common/Templates/PrivateDefault';
import { useOfferDetails } from './hook';
import { Button, Text } from 'iq-blueberry';
import { Container } from '@common/components/Container';
import { Tag } from '@hub/components/OfferCard/Tag';
import { Highlights } from '@hub/components/OfferCard/Highlights';
import './styles.scss';
import InfoAccordion from './components/InfoAccordion';
import { RedirectModal } from '@hub/components/RedirectModal';

export const OfferDetails = () => {
  const { content, onClick, btnLoading, redirectModal } = useOfferDetails();

  if (!content) return null;

  const {
    image,
    title,
    preApproved,
    limit,
    featureInformation,
    highlights,
    btnText,
    detailsText,
  } = content;

  return (
    <PrivateDefaultTemplate>
      <Container>
        <div className="offer-details">
          <div className="offer-details__image">
            <img src={image?.filename} alt={image?.alt} />
          </div>
          <div className="offer-details__title-container">
            <Text
              className="offer-details__title"
              as="h2"
              variant="heading-five"
              variantDesk="heading-two"
              defaultColor="title"
            >
              {title}
            </Text>
            <Tag
              isPreApproved={preApproved}
              limit={limit}
              featureInformation={featureInformation}
            />
          </div>

          <Highlights
            className="offer-details__highlights"
            topics={highlights}
          />

          <Button
            color="default"
            disabled={false}
            expand="x"
            justify="center"
            loading={btnLoading}
            onClick={onClick}
            size="medium"
            style={{ fontSize: '16px', fontWeight: 700 }}
            type="primary"
          >
            {btnText}
          </Button>

          <InfoAccordion info={detailsText} />

          <Button
            color="default"
            disabled={false}
            expand="x"
            justify="center"
            loading={false}
            onClick={() => window.history.back()}
            size="medium"
            style={{ fontSize: '16px', fontWeight: 700 }}
            type="secondary"
          >
            Voltar
          </Button>
        </div>
      </Container>
      {redirectModal && (
        <RedirectModal
          isOpen={redirectModal.isOpen}
          partnerName={redirectModal.name}
          offerImage={redirectModal.image}
        />
      )}
    </PrivateDefaultTemplate>
  );
};
