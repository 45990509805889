export const KeyMap = {
  IQ: {
    SortingHatDev: '69e0db279be7903c3034982b0a3c878ed4652aa3',
    SortignHatProd: 'cd2c8dbf317105d2eccda39cf91881cda9991feb',
  },
  CP: {
    SortingHatDev: 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4',
    SortignHatProd: 'f487679eba4d37216d50b3f11de043d379e4cd7b',
  },
};
