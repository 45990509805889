import { useContext } from 'react';
import { UserContext } from './context';

export const useUserContext = () => {
  const {
    user,
    isAuthenticated,
    isLoading,
    signIn,
    signOut,
    updateUser,
    getUser,
  } = useContext(UserContext);

  return {
    user,
    getUser,
    isAuthenticated,
    isLoading,
    signIn,
    signOut,
    updateUser,
  };
};
