import { Text } from 'iq-blueberry';
import './styles.scss';
import classNames from 'classnames';

type HighlightsProps = {
  topics: [];
  className?: string
};
export const Highlights = (props: HighlightsProps) => {
  const { topics, className } = props;

  const hightlightClass = (topic: any) => {
    return classNames('offer-card-highlights', className, {
      'offer-card-highlights--featured-topic':
        topic?.title && !topic?.description,
    });
  };

  return (
    <div>
      {topics &&
        topics.map((topic: any, index: any) => (
          <div className={hightlightClass(topic)} key={index}>
            <Text as="h5" variant="body-small">
              {topic.title}
            </Text>
            <Text as="span" variant="body-medium">
              <strong>{topic.description}</strong>
            </Text>
          </div>
        ))}
    </div>
  );
};
