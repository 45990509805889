export function createCacheTime(cacheInMinutes: number) {
  const currentTime = new Date();
  const futureTime = new Date(currentTime.getTime() + cacheInMinutes * 60000);

  return futureTime.getTime();
}

export function isCacheExpired(dateToCompare: number) {
  const currentDate = new Date();
  return currentDate.getTime() > dateToCompare;
}
