import classNames from 'classnames';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';
import './styles.scss';

type TagProps = {
  isPreApproved?: boolean;
  limit?: number;
  featureInformation?: string;
};

export const Tag = (props: TagProps) => {
  const { isPreApproved, limit, featureInformation } = props;
  const limitText =
    limit && `Limite pré-aprovado de ${currencyToLocale(limit, false)}`;
  const preApprovedText = isPreApproved && 'Chance alta de aprovação';
  const text = limitText || preApprovedText || featureInformation;

  const tagClass = classNames('offer-card-tag', {
    'offer-card-tag--preApproved': isPreApproved || limit,
    'offer-card-tag--pattern': !isPreApproved && featureInformation,
  });

  if (!text) return null;

  return (
    <div className={tagClass}>
      <p className="offer-card-tag__info">{text}</p>
    </div>
  );
};
