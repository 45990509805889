import { Modal } from '@common/components/Modal';
import CpLogo from '@common/assets/logo-cp-vertical-text.svg';

import './styles.scss';

type RedirectModalProps = {
  isOpen: boolean;
  partnerName: string;
  offerImage: string;
};
export function RedirectModal({
  isOpen,
  partnerName,
  offerImage,
}: RedirectModalProps) {
  return (
    <div className="redirect-modal">
      <Modal
        hasCloseButton={false}
        mobile="center"
        desktop="center"
        showModal={isOpen}
      >
        <div className="loader-container">
          <div className="partner-container">
            <img src={CpLogo} alt="Logo Consumidor Positivo" />
          </div>
          <div className="loader"></div>
          <div className="partner-container">
            <img src={offerImage} alt="Oferta do parceiro" />
          </div>
        </div>
        <div className="message-container">
          <p>
            Em instantes você será redirecionado para o site do {partnerName}
          </p>
        </div>
      </Modal>
    </div>
  );
}
