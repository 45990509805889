import iqBadge from '@common/assets/icon/menu/badge-iq.svg';
import cpBadge from '@common/assets/icon/menu/badge-cp.svg';
import iqChartRetangle from '@common/assets/icon/menu/chart-retangle-iq.svg';
import cpChartRetangle from '@common/assets/icon/menu/chart-retangle-cp.svg';
import iqChartBar from '@common/assets/icon/menu/chart-bar-iq.svg';
import cpChartBar from '@common/assets/icon/menu/chart-bar-cp.svg';
import iqHandCard from '@common/assets/icon/menu/hand-card-iq.svg';
import cpHandCard from '@common/assets/icon/menu/hand-card-cp.svg';
import iqFilledBill from '@common/assets/icon/menu/filled-bill-fold.svg';
import cpFilledBill from '@common/assets/icon/menu/filled-bill-fold-cp.svg';
import iqPadlock from '@common/assets/icon/menu/padlock-iq.svg';
import cpPadlock from '@common/assets/icon/menu/padlock-cp.svg';
import iqBell from '@common/assets/icon/menu/bell-iq.svg';
import cpBell from '@common/assets/icon/menu/bell-cp.svg';
import iqLifebouy from '@common/assets/icon/menu/lifebuoy-iq.svg';
import cpLifebouy from '@common/assets/icon/menu/lifebuoy-cp.svg';
import iqLogout from '@common/assets/icon/menu/logout-iq.svg';
import cpLinkIcon from '@common/assets/icon/chevron-right-cp.svg';
import iqLinkIcon from '@common/assets/icon/chevron-right-iq.svg';
import cpFileChecklist from '@common/assets/icon/cp/file-checklist.svg';
import iqFileChecklist from '@common/assets/icon/file-checklist.svg';
import cpBillBarCode from '@common/assets/icon/cp/bill-bar-code.svg';
import monitoringRadar from '@common/assets/icon/menu/monitoring-radar.svg';
import { RouteMap } from '@common/constants/RouteMap';

export interface item {
  label: string;
  icon: string;
  slug: RouteMap;
  title: string;
}

const iq: Array<item> = [
  {
    label: 'Aumentar meu Score',
    icon: iqBadge,
    slug: RouteMap.Objectives,
    title: 'Aumentar meu Score',
  },
  {
    label: 'Histórico de Score',
    icon: iqChartRetangle,
    slug: RouteMap.ObjectivesHistoric,
    title: 'Histórico de Score',
  },
  {
    label: 'Ver meu progresso',
    icon: iqChartBar,
    slug: RouteMap.ObjectivesProgress,
    title: 'Ver meu progresso',
  },
  {
    label: 'Minhas ofertas',
    icon: iqHandCard,
    slug: RouteMap.Offer,
    title: 'Ofertas',
  },
  {
    label: 'Dívidas negativadas',
    icon: iqFilledBill,
    slug: RouteMap.NegativeDebts,
    title: 'Dívidas negativadas',
  },
  {
    label: 'Consultas em meu CPF',
    icon: iqFileChecklist,
    slug: RouteMap.CpfLookout,
    title: 'Consultas em meu CPF',
  },
  {
    label: 'Alterar senha',
    icon: iqPadlock,
    slug: RouteMap.ChangePassword,
    title: 'Alterar Senha',
  },
  // {
  //   label: 'Notificações',
  //   icon: iqBell,
  //   slug: RouteMap.Notification,
  //   title: 'Notificações',
  // },
  {
    label: 'Central de ajuda',
    icon: iqLifebouy,
    slug: RouteMap.Help,
    title: 'Ajuda',
  },
  {
    label: 'Sair',
    icon: iqLogout,
    slug: RouteMap.Logout,
    title: 'Sair',
  },
];

const cp: Array<item> = [
  {
    label: 'Aumentar meu Score',
    icon: cpBadge,
    slug: RouteMap.Objectives,
    title: 'Aumentar meu Score',
  },
  {
    label: 'Histórico de Score',
    icon: cpChartRetangle,
    slug: RouteMap.ObjectivesHistoric,
    title: 'Histórico de Score',
  },
  {
    label: 'Ver meu progresso',
    icon: cpChartBar,
    slug: RouteMap.ObjectivesProgress,
    title: 'Ver meu progresso',
  },
  {
    label: 'Minhas ofertas',
    icon: cpHandCard,
    slug: RouteMap.Offer,
    title: 'Ofertas',
  },
  {
    label: 'Dívidas para negociar',
    icon: cpBillBarCode,
    slug: RouteMap.MyDebts,
    title: 'Dívidas para negociar',
  },
  {
    label: 'Dívidas negativadas',
    icon: cpFilledBill,
    slug: RouteMap.NegativeDebts,
    title: 'Dívidas negativadas',
  },
  {
    label: 'Consultas em meu CPF',
    icon: cpFileChecklist,
    slug: RouteMap.CpfLookout,
    title: 'Consultas em meu CPF',
  },
  {
    label: 'Alterar senha',
    icon: cpPadlock,
    slug: RouteMap.ChangePassword,
    title: 'Alterar Senha',
  },
  // {
  //   label: 'Central de Monitoramento',
  //   icon: monitoringRadar,
  //   slug: RouteMap.MonitoringCenter,
  //   title: 'Central de Monitoramento',
  // },
  // {
  //   label: 'Notificações',
  //   icon: cpBell,
  //   slug: RouteMap.Notification,
  //   title: 'Notificações',
  // },
  {
    label: 'Central de ajuda',
    icon: cpLifebouy,
    slug: RouteMap.Help,
    title: 'Ajuda',
  },
];

export const itensMenu = import.meta.env.VITE_APP_THEME ? cp : iq;

export const rightIcon = import.meta.env.VITE_APP_THEME
  ? cpLinkIcon
  : iqLinkIcon;
