import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';
import { OfferDetails } from '@hub/pages/OfferDetails';

const Offers = lazy(() => import('@hub/pages/Offers'));

export function Router() {
  return (
    <>
      <Route path={RouteMap.Offer} element={<Offers />} />;
      <Route path={RouteMap.OfferDetails} element={<OfferDetails />} />;
    </>
  );
}
