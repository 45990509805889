import { useLocation, useNavigate } from 'react-router-dom';

import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';
import { useState } from 'react';

export const useHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { signOut } = useUserContext();
  const { elementClickedEvent } = useTracking();
  const showBackButton = !pathname.includes('home');
  const [showMenu, setShowMenu] = useState(false);

  function toogleMenu(){
    setShowMenu(!showMenu);
  }

  function handleGoBack() {
    elementClickedEvent({
      elementType: 'button/icon',
      text: '<-',
      location: 'navbar',
    });
    window.history.back();
  }

  function handleNavigate(slug: RouteMap, title: string) {
    elementClickedEvent({
      elementType: 'button/text',
      name: slug,
      text: title,
      location: 'navbar',
    });
    slug === RouteMap.Logout ? signOut(true) : navigate(slug);
  }

  return {
    showBackButton,
    showMenu,
    handleGoBack,
    handleNavigate,
    toogleMenu,
  };
};
