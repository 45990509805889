import { useState } from 'react';
import { useStoryblokCache } from '@libs/storyblok';
import { IOfferProps, SBData, SHData } from '@hub/types';

export const useOffers = (useShJson = false) => {
  const { product, sortingHat } = useStoryblokCache({getSH: useShJson});

  const [sortingHatDecide, setSortingHatDecide] = useState<any>({stories: []});

  const getInfoAboutOffers = (shDecisions: SHData[]): IOfferProps[] => {
    if (sortingHatDecide?.stories?.length === 0) {
      setSortingHatDecide({stories: [...shDecisions]});
    }

    const filteredProducts = shDecisions
      .map((decision) => {
        const sbInfo = !useShJson ? 
          decision?.metadata
          :
          sortingHat?.stories?.find(
            (storie: SBData) =>
              storie?.slug === decision.slug ||
              storie?.content?.slug === decision.slug
          );

        const sbInfoProduct = product?.stories?.find((storie: SBData) => {
          const slug = storie?.content?.product_slug || storie?.content?.Slug;
          return slug === decision.slug;
        });

        return {
          slug: decision.slug,
          storyblok_content: sbInfo?.content,
          storyblok_content_product: sbInfoProduct?.content,
          sorting_hat: decision,
        };
      })
      .filter(Boolean);

    return filteredProducts;
  };

  return {
    getInfoAboutOffers,
    product,
    sortingHat: useShJson ? sortingHat : sortingHatDecide
  };
};
