// @ts-nocheck

export const appsFlyerBridge = () => {
  const logEvent = (event: string, data: Record<string, unknown>) => {
    if (!window?.isNativeApp || !window?.NativeFeatures?.AppsFlyer) return;

    const payload = {
      action: 'sendAppsFlyerEvent',
      payload: { eventName: event, data },
    };

    const stingfiedPayload = JSON.stringify(payload);
    window.ReactNativeWebView.postMessage(stingfiedPayload);
  };

  return { logEvent };
};
