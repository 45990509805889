import { useTracking } from '@common/hooks/useTracking';
import { useACRedirect } from '@dues/hooks/useACRedirect';
import { ElementEventPayload } from '@common/hooks/useTracking/types';

const useFooter = () => {
  const { elementClickedEvent } = useTracking();
  const { sendProposalStartedAndRedirectToAC } = useACRedirect();

  function handleClick(e: React.MouseEvent<HTMLAnchorElement> | undefined) {
    const { tagName, dataset, innerText } = e?.target as HTMLElement;
    const eventPayload = {
      elementType: 'link',
      location: 'footer',
      position: dataset.category,
      text: tagName === 'A' ? innerText : dataset.src,
    } as ElementEventPayload;

    if (innerText === 'Acordo Certo') {
      e?.preventDefault();
      const params =
        '?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=footer-home&utm_content=logged-in';
      sendProposalStartedAndRedirectToAC(params);
    }

    elementClickedEvent(eventPayload);
  }

  return {
    handleClick,
  };
};

export default useFooter;
