import { StorageMap } from '@common/constants/StorageMap';
import { handleErrorLog } from './handleErrorLogs';

type StorageType = 'local' | 'session';

export function getFromStorage<T>(key: string): T | null {
  try {
    const storageTypes: StorageType[] = ['local', 'session'];
    let value;
    for (const storageType in storageTypes) {
      const storage = storageType === 'local' ? localStorage : sessionStorage;
      const storageItem = storage.getItem(key || '');
      if (storageItem) {
        value = storageItem;
        break;
      }
    }
    if (!value) return null;
    const decodedValue = window.atob(value);
    return JSON.parse(decodedValue) as T;
  } catch (error) {
    handleErrorLog(error);
    return null;
  }
}

export function setOnStorage(saveOn: StorageType, key: string, value: unknown) {
  const storage = saveOn === 'local' ? localStorage : sessionStorage;

  try {
    const serializedValue = JSON.stringify(value);
    const encodedValue = window.btoa(serializedValue);
    storage.setItem(key, encodedValue);
  } catch (error) {
    handleErrorLog(error);
  }
}

export function removeFromStorage(
  keys?: string | string[],
  from?: StorageType
): void {
  const storageTypes: StorageType[] = from ? [from] : ['local', 'session'];
  const keysToRemove: string[] = [];

  if (!keys || keys?.length === 0) {
    const storageKeys = Object.values(StorageMap);
    keysToRemove.push(...storageKeys);
  } else {
    const implicitKeys = Array.isArray(keys) ? keys : [keys];
    keysToRemove.push(...implicitKeys);
  }

  storageTypes.forEach((storageType) => {
    const storage = storageType === 'local' ? localStorage : sessionStorage;
    keysToRemove.forEach((key) => storage.removeItem(key || ''));
  });
}
