import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { useUserContext } from '@common/contexts/UserContext';
import { useGlobalEvents } from './useGlobalEvents';
import { useTracking } from '@common/hooks/useTracking';

type WebviewBridgeProps = {
  children?: React.ReactNode;
};

export const WebviewBridge = ({ children }: WebviewBridgeProps) => {
  useGlobalEvents();
  const { user, isLoading, isAuthenticated } = useUserContext();
  const { appInfoTracked } = useTracking();

  useEffect(() => {
    if (!window.isNativeApp) return;

    const onMessageFromRN = (data: string) => {
      try {
        const { action, payload } = JSON.parse(data);

        const event = new CustomEvent(`rn-${action}`, { detail: payload });
        window.dispatchEvent(event);
      } catch (error) {
        console.error(error);
      }
    };

    window.onMessageFromRN = onMessageFromRN;
  }, []);

  useEffect(() => {
    if (!window.isNativeApp) return;

    const appVersion = Cookies.get('app-version');
    if (!appVersion) return;

    const stringfiedDevice = Cookies.get('device');
    if (!stringfiedDevice) return;
    const device = JSON.parse(stringfiedDevice) as Record<string, string>;

    const deviceInfo = Object.entries(device).reduce((acc, [key, value]) => {
      acc[key] = typeof value === 'string' ? value.toLowerCase() : value;
      return acc;
    }, {} as Record<string, string>);

    appInfoTracked({ deviceInfo, storeAppVersion: appVersion });
  }, []);

  useEffect(() => {
    if (!window.isNativeApp) return;

    if (!user) return;
    const payload = { action: 'userLoggedIn', payload: user };
    const stingfiedPayload = JSON.stringify(payload);
    window.ReactNativeWebView.postMessage(stingfiedPayload);
  }, [user]);

  useEffect(() => {
    if (!window.isNativeApp) return;

    if (isLoading || isAuthenticated) return;
    const payload = { action: 'userLoggedOut', payload: null };
    const stingfiedPayload = JSON.stringify(payload);
    window.ReactNativeWebView.postMessage(stingfiedPayload);
  }, [isLoading, isAuthenticated]);

  return children;
};
