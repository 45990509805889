import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useOfferRedirect } from '@hub/hooks/useOfferRedirect';
import useSortingHat from '@common/apis/sortingHatAdapter';
import { useOffers } from '@hub/hooks/useOffers';
import { IOfferProps, SHData } from '@hub/types';

export const useOfferDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { decisions } = useSortingHat();
  const { handleClick, redirectModal } = useOfferRedirect();
  
  const useShJson = true;
  const { getInfoAboutOffers } = useOffers(useShJson);
  
  const [currentOfferInfo, setCurrentOfferInfo] = useState<IOfferProps | null>(
    null
  );
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any | null>(null);

  function getCurrentSlug() {
    const pathname = location.pathname.split('/ofertas/');
    const currentSlug = pathname.length > 1 ? pathname[1] : '';
    return currentSlug;
  }

  const setDecisions = useCallback(() => {
    decisions && getCurrentOfferInfo(decisions);
  }, [decisions]);

  const getCurrentOfferInfo = (shDecisions: SHData[]) => {
    const slug = getCurrentSlug();
    const isItauCard = slug.includes('itau') && slug.includes('cards');

    if (!isItauCard) return navigate('404');

    const defaultDecision = [
      {
        slug,
        id: slug,
        category: 'card',
        partner: 'itau',
      },
    ];
    const shDecision = shDecisions?.filter(
      (decision: SHData) => decision.slug === slug
    );
    const offer = shDecision?.length > 0 ? shDecision : defaultDecision;
    const info = getInfoAboutOffers(offer);
    const currentOfferInfo = info && info[0];

    if (currentOfferInfo) {
      setCurrentOfferInfo(currentOfferInfo);
      buildPageContent(currentOfferInfo);
    }
  };

  const buildPageContent = (currentOfferInfo: IOfferProps) => {
    if (!currentOfferInfo) return;

    const {
      storyblok_content_product: productData,
      storyblok_content: shData,
      sorting_hat: decisionData,
    } = currentOfferInfo;

    if (!productData || !shData || !decisionData) return;

    const offerDetailsContent = {
      slug: decisionData?.slug,
      preApproved: Boolean(decisionData?.preApproved),
      limit: Number(decisionData?.limit),
      title: shData?.title,
      highlights: shData?.highlights_card_iq,
      featureInformation: shData?.feature_information,
      btnText: shData?.button_text,
      image: productData?.image,
      detailsText: productData?.product_benefits,
      partner: decisionData?.partner,
      integrations_list: productData?.integrations_list,
    };

    setContent(offerDetailsContent);
  };

  const onClick = () => {
    if (!currentOfferInfo) return;
    const { storyblok_content, storyblok_content_product, sorting_hat } =
      currentOfferInfo;

    setBtnLoading(true);
    handleClick(
      storyblok_content,
      storyblok_content_product,
      sorting_hat,
      0,
      'pagina-produto'
    ).finally(() => setBtnLoading(false));
  };

  useEffect(() => setDecisions(), [setDecisions]);

  return {
    content,
    btnLoading,
    redirectModal,
    onClick,
  };
};
