import { useEffect } from 'react';
import { Conditional, FooterClassic } from 'iq-blueberry';
import 'iq-blueberry/src/core/components/legacy/FooterClassic.styl';

import { LoggedInHeader } from '@common/components/LoggedInHeader';
import { LoggedOutHeader } from '@common/components/LoggedOutHeader';
import { Footer } from '@common/components/Footer';
import { useUserContext } from '@common/contexts/UserContext';

import './styles.scss';
interface IPrivateDefaultProps {
  children: React.ReactNode;
}
export const PrivateDefaultTemplate: React.FC<IPrivateDefaultProps> = ({
  children,
}) => {
  const { user, getUser } = useUserContext();

  useEffect(() => {
    getUser();
  }, [user]);

  return (
    <>
      <Conditional
        condition={!!user}
        renderIf={<LoggedInHeader username={user?.full_name} />}
        renderElse={<LoggedOutHeader />}
      />

      <main className="private-default-template">{children}</main>

      <Conditional
        condition={import.meta.env.VITE_APP_THEME === 'CP'}
        renderIf={<Footer />}
        renderElse={
          <div className="footer-classic__wrapper">
            <FooterClassic />
          </div>
        }
      />
    </>
  );
};
