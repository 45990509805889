import { useState } from 'react';
import { Button, Text } from 'iq-blueberry';

import { Container } from '@common/components/Container';
import { Footer } from '@common/components/Footer';

import cpLogo from '@common/assets/logo-cp.svg';
import infoBox from '@common/assets/icon/info-box.svg';
import globalError from '@common/assets/illustration/global-error.svg';

import './styles.scss';

export const GlobalError = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleReload = () => {
    setIsLoading(true);

    window.location.href = `/?t=${Date.now()}`;
  };

  return (
    <main className="global-error">
      <header className="global-error__header">
        <Container customClass="global-error__navbar">
          <img
            className="global-error__logo"
            src={cpLogo}
            alt="Logo Consumidor Positivo"
          />
        </Container>
      </header>
      <Container customClass="global-error__content">
        <div className="global-error__left">
          <img
            className="global-error__icon"
            src={infoBox}
            alt="Tringulo com interrogação"
          />
          <Text as="h1" variantDesk="heading-two" variant="heading-four">
            Ops! Parece que algo deu errado
          </Text>
          <Text
            as="p"
            variantDesk="body-large"
            variant="body-medium"
            defaultColor="common"
          >
            Não foi possível carregar a página, mas não se preocupe. Estamos
            trabalhando nisso, por favor, tente novamente.
          </Text>
          <Button
            size="medium"
            type="primary"
            color="default"
            expand="x"
            justify="center"
            disabled={false}
            loading={isLoading}
            onClick={handleReload}
          >
            Tentar novamente
          </Button>
        </div>
        <div className="global-error__right">
          <img
            src={globalError}
            alt="Mulher sentada utilizando um notebook no colo"
          />
        </div>
      </Container>
    </main>
  );
};
