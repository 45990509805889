import cpBack from '@common/assets/icon/chevron-left-cp.svg';
import iqBack from '@common/assets/icon/chevron-left-iq.svg';
import cpLogo from '@common/assets/logo-cp.svg';
import iqLogo from '@common/assets/logo-iq.svg';
import cpHamburguer from '@common/assets/icon/hamburguer-cp.svg';
import iqHamburguer from '@common/assets/icon/hamburguer-iq.svg';
import cpBell from '@common/assets/icon/bell-cp.svg';
import iqBell from '@common/assets/icon/bell-iq.svg';
import cpClose from '@common/assets/icon/close-cp.svg';
import iqClose from '@common/assets/icon/close-iq.svg';

const iq = {
  logo: { src: iqLogo, alt: 'Home' },
  back: { src: iqBack, alt: 'Voltar para página anterior' },
  notify: { src: iqBell, alt: 'Notificação' },
  hamburguer: { src: iqHamburguer, alt: 'Menu' },
  close: { src: iqClose, alt: 'Fechar' },
};

const cp = {
  logo: { src: cpLogo, alt: 'Home' },
  back: { src: cpBack, alt: 'Voltar para página anterior' },
  notify: { src: cpBell, alt: 'Notificação' },
  hamburguer: { src: cpHamburguer, alt: 'Menu' },
  close: { src: cpClose, alt: 'Fechar' },
};

export const icons = import.meta.env.VITE_APP_THEME ? cp : iq;
