import { isCP } from './handleApp';

export function handleEnvVar(envName: string, allowEmpty?: boolean) {
  if (!envName.startsWith('VITE_')) throw new Error('[INVALID_ENV_VAR]');
  const prefix = isCP ? 'CP_' : 'IQ_';
  const varAppName = envName.replace('VITE_', `VITE_${prefix}`);

  const envVar = import.meta.env[varAppName];
  if (!envVar && !allowEmpty) throw new Error(`[INVALID_ENV_VAR]: ${envName}`);
  else if (!envVar && allowEmpty) return '';
  else return envVar;
}
