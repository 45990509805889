import { Redline } from 'redline-client-sdk';
import { RLExperienceEvents } from '../types';
import { pixel } from './pixel';
import { appsFlyerBridge } from './appsFlyer';

export const experienceEvents = (rl: Redline): RLExperienceEvents => ({
  redirectRequested: (payload = {}) => {
    const event = 'experience.RedirectRequested.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
    appsFlyerBridge().logEvent(event, { event, ...payload });
  },
  pageNotFound: (payload = {}) => {
    const event = 'experience.PageNotFound.v1';
    rl.track(event, payload);
    pixel(event, payload);
    appsFlyerBridge().logEvent(event, payload);
  },
  proposalStarted: (payload = {}) => {
    const event = 'experience.ProposalStarted.v1';
    pixel(event, payload);
    appsFlyerBridge().logEvent(event, payload);
    return rl.track(event, payload);
  },
  sessionRecorded: (payload = {}) => {
    const event = 'experience.SessionRecorded.v1';
    rl.track(event, payload);
    pixel(event, payload);
    appsFlyerBridge().logEvent(event, payload);
  },
  loadingStarted: (payload) => {
    const event = 'experience.LoadingStarted.v1';
    appsFlyerBridge().logEvent(event, payload);
    return rl.track(event, payload);
  },
  loadingCompleted: (payload) => {
    const event = 'experience.LoadingCompleted.v1';
    appsFlyerBridge().logEvent(event, payload);
    return rl.track(event, payload);
  },
});
