import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';

const MyObjectives = lazy(() => import('../pages/MyObjectives'));
const ObjectiveDetail = lazy(() => import('../pages/ObjectiveDetail'));
const MyProgress = lazy(() => import('../pages/MyProgress'));
const Historic = lazy(() => import('../pages/Historic'));
const NegativeDebts = lazy(() => import('../pages/NegativeDebts'));
const CpfLookout = lazy(() => import('../pages/CpfLookout'));

export function Router() {
  return (
    <>
      <Route path={RouteMap.Objectives} element={<MyObjectives />} />
      <Route path={RouteMap.ObjectiveDetail} element={<ObjectiveDetail />} />
      <Route path={RouteMap.ObjectivesProgress} element={<MyProgress />} />
      <Route path={RouteMap.ObjectivesHistoric} element={<Historic />} />
      <Route path={RouteMap.NegativeDebts} element={<NegativeDebts />} />
      <Route path={RouteMap.CpfLookout} element={<CpfLookout />} />
    </>
  );
}
