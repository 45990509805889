/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';
import { useSortingHatV3 } from 'iq-sorting-hat-client-sdk';

import { delay } from '@libs/utils/helpers/delay';
import { isAndroid, isIOS } from '@libs/utils/helpers/mobileOS';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';
import { getUFDigitFromCPF } from '@libs/utils/helpers/getUFDigitFromCPF';
import { getRDG } from '@common/utils/getRDGFormatted';
import { getDDD } from '@libs/utils/helpers/getDDD';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';

import { isCP } from '@common/utils/handleApp';
import { KeyMap } from '@common/constants/KeyMap';
import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';
import { handleErrorLog } from '@common/utils/handleErrorLogs';

export default function useSortingHat() {
  const { user, getUser } = useUserContext();
  const {
    shDecisionEnrichedEvent,
    shDecisionRequestedEvent,
    shDecisionOutcomeReceivedEvent,
  } = useTracking();
  const [documentEncrypted, setDocumentEncrypted] = useState('');
  const anonymousId = getAnonymousID();

  const appName = isCP ? 'cp' : 'newco';

  const [trackDone, setTrackDone] = useState(false);
  const [decisions, setDecisions] = useState();

  const { shTrack: shV3Track, shDecision: shV3Decision } = useSortingHatV3({
    trackEvent: shDecisionEnrichedEvent,
    decisionRequestEvent: shDecisionRequestedEvent,
    appName,
    workflowId: 'cp-tree',
    isDev: import.meta.env.DEV,
    key: {
      dev:
        import.meta.env.VITE_APP_THEME === 'CP'
          ? KeyMap.CP.SortingHatDev
          : KeyMap.IQ.SortingHatDev,
      prod:
        import.meta.env.VITE_APP_THEME === 'CP'
          ? KeyMap.CP.SortignHatProd
          : KeyMap.IQ.SortignHatProd,
    },
    extraTrackData: {
      anonymousId,
      partner: 'newco',
      identifier: user?.uuid,
      workflow_id: 'cp-tree',
    },
  });

  async function callSortingHatTrack() {
    const fieldSlug = 'track';
    const inputValue = 'ok';
    const device = isAndroid() ? 'android' : isIOS() ? 'ios' : 'web';

    const fieldsPayload = {
      application: 'newco',
      anonymousId,
      document: user?.document || '',
      mainPhone: user?.phone,
      fullName: user?.full_name,
      email: user?.email,
      partner: 'newco',
      device,
      identifier: user?.uuid,
      sessionId: getSessionID(),
      fbCookies: getFbAvailableCookies(),
      gaCookies: getGAAvailableCookies(),
      cpfUfEmissor: getUFDigitFromCPF(user?.document || ''),
      ddd: getDDD(user?.phone || ''),
      rdg: getRDG(user?.document || ''),
      source_url: getCookie('start_source_url') || window?.location.href
    };
    const isValid = true;
    const checkDocument = true;

    try {
      await shV3Track(
        fieldSlug,
        inputValue,
        fieldsPayload,
        isValid,
        checkDocument
      );
      if (!trackDone) {
        setTrackDone(true);
      }
    } catch (error) {
      handleErrorLog(error);
    }
  }

  async function callSortingHatDecision() {
    const payload = {
      identifier: user?.uuid,
      workflow_id: 'cp-tree',
    };

    try {
      await delay(1000);
      const data = await shV3Decision(payload);
      const decisionsResults: any = data?.result?.decisions;

      setDecisions(decisionsResults);
      sendEvent(decisionsResults);
    } catch (error) {
      handleErrorLog(error);
    }
  }

  function sendEvent(decisionResults: any[]) {
    const products = decisionResults?.map((result) => {
      return {
        productCategory: result.category,
        productId: result.slug,
      };
    });
    shDecisionOutcomeReceivedEvent(products);
  }

  useEffect(() => {
    if (user) {
      setDocumentEncrypted(user?.document ? btoa(user?.document) : '');
    } else {
      getUser();
    }
  }, [user]);

  useEffect(() => {
    if (documentEncrypted.length > 0) {
      callSortingHatTrack();
    }
  }, [documentEncrypted]);

  useEffect(() => {
    if (documentEncrypted.length > 0) {
      callSortingHatDecision();
    }
  }, [trackDone]);

  return {
    decisions,
  };
}
