const RLPayloadCreator = {
  EventsDebtsContext(props: {
    debt: any;
    paymentOption: any;
    agreement?: any;
    customPaymentMethod?: string;
  }) {
    const { debt, paymentOption, agreement, customPaymentMethod } = props;

    return {
      correlationId: RLPayloadCreator.createCorrelationId(debt),
      partnerContext: RLPayloadCreator.partnerContext(debt?.partner || debt),
      debtContext: RLPayloadCreator.debtContext(debt, paymentOption),
      ...(agreement && {
        agreementContext: RLPayloadCreator.agreementContext(
          agreement,
          paymentOption,
          customPaymentMethod
        ),
      }),
    };
  },

  createCorrelationId(debt: any) {
    return `${debt?.id}|${debt?.partner?.partnerIdentifier || debt?.partnerIdentifier
      }`;
  },

  partnerContext(partnerSystem: any) {
    return {
      partnerId: partnerSystem?.id,
      partnerIdentifier: partnerSystem?.partnerIdentifier,
      identifier: partnerSystem?.identifier,
    };
  },

  debtContext(debt: any, paymentOption: any) {
    const splittedDateHour = (date: any) => date?.split('T')[0];

    const originalDate =
      splittedDateHour(debt?.detalhes?.[0]?.dataOriginalDivida) ||
      splittedDateHour(debt?.details?.[0]?.originalDebtDate);

    const maxInstallments =
      debt?.maxNumberInstallment ||
      debt?.opcaoPagamento?.[debt?.opcaoPagamento?.length - 1]?.qtdParcela ||
      0;

    return {
      debtId: Number(debt?.id),
      isNegative: debt?.debtIsNegative,
      agreementValue: paymentOption?.valorAcordo || paymentOption?.agreementValue,
      originalValue: debt?.valor || paymentOption?.originalDebtValue,
      currentValue: debt?.valorDivida || paymentOption?.currentDebtValue,
      ...(debt?.valorMinimo && { minimalValue: debt?.valorMinimo }),
      discountPercentage: paymentOption?.percentualDesconto || paymentOption?.discountPercentage,
      totalInstallments: paymentOption?.qtdParcela || paymentOption?.numberInstallment,
      product: debt?.produto || debt?.product,
      contract: debt?.contrato || debt?.contract,
      identifier: debt?.identificador || debt?.identifier,
      isLeadRedirect: debt?.partner?.isLeadRedirect,
      originalDebtDate: originalDate,
      maxNumberInstallment: maxInstallments
    };
  },

  agreementContext: (
    agreement: any,
    paymentOption: any,
    customPaymentMethod?: string
  ) => {
    return {
      paymentMethod: customPaymentMethod || paymentOption?.paymentMethodName,
      agreementId: agreement?.id,
      paymentOptionId: paymentOption?.id,
      agreementValue: paymentOption?.valorAcordo,
      numberOfInstallments: paymentOption?.qtdParcela,
      installmentValue: paymentOption?.valorParcela,
      entryValue: paymentOption?.valorEntrada,
    };
  },
};

export default RLPayloadCreator;
