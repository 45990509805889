import { useUserContext } from '@common/contexts/UserContext';
import { useTracking } from '@common/hooks/useTracking';
import {
  isUserFromWebView,
  redirectInApp,
} from '@common/utils/webviewComunication';
import { useApiFirebolt } from '@hub/apis/firebolt/useApiFirebolt';
import { SBContent, SHData } from '@hub/types';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSessionID, getAnonymousID } from 'redline-client-sdk';
import { getSiteIdParam } from '@libs/utils/helpers/getSiteIdParam';
import { getDeliveryIdParam } from '@libs/utils/helpers/getDeliveryIdParam';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { getFromStorage } from '@common/utils/handleStorage';
import { StorageMap } from '@common/constants/StorageMap';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';
import {
  addAutofillParam,
  FireboltUserInfo,
} from '@libs/utils/helpers/addAutofillParam';

import { RouteMap } from '@common/constants/RouteMap';
import { useStoryblokDatasource } from '@libs/storyblok';
import { isIOS } from '@libs/utils/helpers/mobileOS';
import { isProd } from '@libs/utils/helpers/isProd';
import { useState } from 'react';

interface ProductClickedProps {
  product: {
    brand: string;
    category: string;
    location: string;
    name: string;
    position: number;
    productId: string;
    text: string;
    variant: string;
  };
}

export function useOfferRedirect() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    productClickedEvent,
    redirectRequestedEvent,
    proposalStartedEvent,
    elementClickedEvent,
  } = useTracking();
  const { sendSoftProposal } = useApiFirebolt();
  const { loading } = sendSoftProposal;
  const { user } = useUserContext();
  const [cookies] = useCookies(['app_version']);
  const { getDatasource } = useStoryblokDatasource();
  const [redirectModal, setRedirectModal] = useState<{
    isOpen: boolean;
    name: string;
    image: string;
  } | null>(null);

  async function handleClick(
    contentSh: SBContent,
    contentProduct: SBContent,
    shInfo: SHData,
    index: number,
    location: string
  ) {
    const productClickedEventPayload = {
      product: {
        brand: contentSh?.partner,
        category: contentSh?.category,
        location,
        name: contentSh?.name,
        position: index,
        productId: contentSh?.slug,
        text: contentSh?.title,
        variant: contentSh?.variant,
      },
    };

    await handleRedirect(
      contentSh,
      contentProduct,
      shInfo,
      productClickedEventPayload,
      location
    );
  }

  async function handleRedirect(
    contentSh: SBContent,
    contentProduct: SBContent,
    shInfo: SHData,
    productClickedEventPayload: ProductClickedProps,
    location: string
  ) {
    const isACOffer = contentSh?.partner === 'acordocerto';
    const isItauOffer = contentSh?.partner === 'itau';

    if (isACOffer) {
      productClickedEvent({
        ...productClickedEventPayload,
        url: RouteMap.MyDebts,
      });
      elementClickedEvent({
        elementType: 'button',
        location: pathname,
        name: 'Modal AC SH - Conferir se tenho dívidas',
      });
      return navigate(RouteMap.MyDebts);
    }

    if (isItauOffer) {
      setRedirectModal({
        isOpen: true,
        name: contentSh.partner,
        image: contentSh.image_logo.filename,
      });
    }

    trackAndRedirectOffer(
      contentSh,
      shInfo,
      location,
      productClickedEventPayload
    );
  }

  async function trackAndRedirectOffer(
    contentSh: SBContent,
    shInfo: SHData,
    location: string,
    productClickedEventPayload?: ProductClickedProps
  ) {
    const isBBCard = contentSh?.partner === 'bancodobrasil';
    const proposalSoft = isBBCard
      ? null
      : await requestToFireboltSkipper(
          contentSh?.slug,
          contentSh?.partner,
          contentSh?.partner
        );

    const fireboltId = proposalSoft?.firebolt_id || '';

    let url = await getUrl(
      contentSh?.links,
      Boolean(shInfo['preApproved']),
      fireboltId
    );

    if (!url) return;

    if (contentSh.full_funnel) {
      const userInfo: FireboltUserInfo = {
        cpf: user?.document,
        fullName: user?.full_name,
        email: user?.email,
        phone: user?.phone,
      };

      // FIXME: Temp solution to redirect to Juvo
      // After the implementation of the new Juvo page, we can trust in storyblok link
      let tempJuvoUrl = url;
      if (!url.includes('/produtos/juvo')) {
        tempJuvoUrl = isProd()
          ? 'https://consumidorpositivo.com.br/produtos/juvo'
          : 'https://dev.consumidorpositivo.com.br/produtos/juvo';
      }

      url = addAutofillParam({
        productSlug: contentSh.slug,
        url: tempJuvoUrl,
        user: userInfo,
      });
    }

    redirectRequestedEvent(contentSh?.slug, pathname, url, fireboltId);
    productClickedEvent({ ...productClickedEventPayload, url });
    elementClickedEvent({
      elementType: 'button',
      location: pathname,
      name: `${location} - ${contentSh.button_text}`,
    });
    await sendEventProposalStarted(contentSh, url, fireboltId);

    redirect(url);
    setRedirectModal(null);
  }

  const getUrl = async (
    links: SBContent['links'],
    preApproved: boolean,
    fireboltId: string
  ) => {
    let url = getStoryblokUrl(links, preApproved);

    if (url) {
      const urlParams = getFromStorage<string>(StorageMap.UrlParams);

      if (urlParams) {
        url = `${url}&${urlParams}`;
      }

      const completedUrl = await fillUrlParameters(url, fireboltId);
      return completedUrl;
    }
  };

  const getStoryblokUrl = (links: any, isPreApproved: boolean) => {
    const linksCP = links.find(
      (link: any) => link.application === 'cp-logged-links'
    );

    if (!linksCP) return;

    const link = isPreApproved
      ? linksCP?.link_pre_approved
      : linksCP?.link_without_debts;

    return link.url;
  };

  const fillUrlParameters = async (url: string, fireboltId: string) => {
    const startSourceUrl = getCookie('start_source_url') || '';
    const params = {
      fireboltId,
      sessionId: getSessionID(),
      deliveryId: getDeliveryIdParam({ startSourceUrl }),
      siteId:
        (await getSiteIdParam({
          marketplace: 'cp',
          startSourceUrl,
          getDatasource,
        })) || '',
    };
    const completedUrl = url
      .replace('(session_id)', getSessionID())
      .replace('(firebolt_id)', params.fireboltId)
      .replace('(delivery_id)', params.deliveryId)
      .replace('(af_siteid)', params.siteId);

    return completedUrl;
  };

  function redirectToOfferDetailsPage(
    contentSh: SBContent,
    contentProduct: SBContent,
    shInfo: SHData,
    productClickedEventPayload?: ProductClickedProps
  ) {
    const url = getUrl(
      contentSh?.links,
      Boolean(shInfo['preApproved']),
      '(firebolt_id)'
    );
    productClickedEvent({ ...productClickedEventPayload, url });
    navigate(`/ofertas/${contentSh?.slug}`);
  }

  async function requestToFireboltSkipper(
    slug: string,
    issuer: string,
    partner: string,
    additionalItem?: any,
    additionalMetadata?: any
  ) {
    const item = {
      cpf: user?.document,
      full_name: user?.full_name,
      email: user?.email,
      main_phone: user?.phone,
      date_of_birth: user?.birthdate,
      choosen_card: slug,
      issuer: issuer,
      ...additionalItem,
    };
    const metadata = {
      start_source_url: getCookie('start_source_url'),
      user_agent: window.navigator.userAgent,
      anonymousId: getAnonymousID(),
      sessionId: getSessionID(),
      fbCookies: {
        fcbId: getFbAvailableCookies().fbc,
        fbpId: getFbAvailableCookies().fbp,
      },
      gaCookies: {
        gaId: getGAAvailableCookies().ga,
      },
      ...additionalMetadata,
    };
    const proposalResponse = await sendSoftProposal.send(partner, {
      item,
      metadata,
    });

    return proposalResponse;
  }

  /**
   * Redirects the user to the given URL
   * For some reason, in Android the "window.location.href" doesn't work :(
   * And, for some another reason, in iOS the "window.open" doesn't work :(
   * For this reason, responsibility for redirection has been assigned to React Native in apps updated from version 5.0.3
   *
   * So, this 'gambetas' is needed to make it work in both platforms
   *
   * Feel free to improve this function!! (if you can test on both platforms, in more than 1 device!!)
   */

  function redirect(url: string) {
    if (isUserFromWebView() && cookies.app_version) return redirectInApp(url);
    if (isIOS()) return window.open(url, '_top');
    return window.open(url, '_blank');
  }

  const sendEventProposalStarted = async (
    contentOffer: SBContent,
    redirectUrl: string,
    fireboltId: string
  ) => {
    if (!contentOffer) return;

    const { slug, category } = contentOffer;
    if (contentOffer?.full_funnel) return;

    const payload = {
      initialSlug: slug,
      finalSlug: slug,
      category,
      redirectUrl,
      fireboltId,
    };
    await proposalStartedEvent(payload);
  };

  return {
    handleClick,
    trackAndRedirectOffer,
    getUrl,
    loading,
    redirectModal,
  };
}
