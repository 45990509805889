import { useRequest } from 'alova';
import { alovaScoreInstance } from './alovaScoreInstance';
import {
  IObjectiveResponse,
  IScoreApiResponse,
  IHistoricResponse,
  IScoreResponse,
  NegativeInfo,
  CpfLookout,
} from '@tally/types';

export const useApiScore = () => {
  const scoreRequest = useRequest(
    alovaScoreInstance.Get<IScoreApiResponse<IScoreResponse>>('/v1/score'),
    { immediate: false }
  );

  const objectivesRequest = useRequest(
    alovaScoreInstance.Get<IScoreApiResponse<IObjectiveResponse[]>>(
      '/v1/score/objectives'
    ),
    { immediate: false }
  );

  const historicRequest = useRequest(
    alovaScoreInstance.Get<IScoreApiResponse<IHistoricResponse[]>>(
      '/v1/score/history'
    ),
    { immediate: false }
  );

  const negativeRequest = useRequest(
    alovaScoreInstance.Get<IScoreApiResponse<NegativeInfo[]>>(
      '/v1/negative/consult'
    ),
    { immediate: false }
  );

  const cpfLookoutRequest = useRequest(
    alovaScoreInstance.Get<IScoreApiResponse<CpfLookout[]>>(
      '/v1/customer/data'
    ),
    { immediate: false }
  );

  return {
    scoreRequest,
    objectivesRequest,
    historicRequest,
    negativeRequest,
    cpfLookoutRequest,
  };
};
