import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RedlineProvider } from '@libs/redline';
import { UserProvider } from '@common/contexts/UserContext/context';
import { DogxProvider } from '@libs/dogx';

import { WebviewBridge } from '@common/components/WebviewBridge';
import App from './App';
import { handleEnvVar } from '@common/utils/handleEnvVar';
import getRedlineAppName from '@common/utils/getRedlineAppName';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const redlineAppName = getRedlineAppName();

root.render(
  <RedlineProvider
    writeKey={handleEnvVar('VITE_REDLINE_WRITE_KEY')}
    mode={import.meta.env.VITE_ENV}
    appName={redlineAppName}
    logs={false}
    appVersion={import.meta.env.VITE_DEPLOY_VERSION}
  >
    <DogxProvider>
      <UserProvider>
        <BrowserRouter>
          <WebviewBridge>
            <App />
          </WebviewBridge>
        </BrowserRouter>
      </UserProvider>
    </DogxProvider>
  </RedlineProvider>
);
