import { isCP } from './handleApp';
import { isUserFromWebView } from './webviewComunication';

export default function getRedlineAppName() {
  const baseName = isCP ? 'cp' : 'iq';
  // isUserFromWebView is app version 5.x.x-
  // window.isNativeApp is app version 6.x.x+
  const typeName =
    isUserFromWebView() || window.isNativeApp ? 'mobile-app' : 'webapp';

  return `${baseName}-${typeName}`;
}
