import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { delay } from '@libs/utils/helpers/delay';

import { LoaderCircular } from '@common/components/LoaderCircular';
import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';
import { isCP } from '@common/utils/handleApp';
import useDatadogRum from '@libs/utils/hooks/useDatadogRum';

import PublicRoutes from '@common/routes/PublicRoutes';
import PrivateRoutes from '@common/routes/PrivateRoutes';

import { Redirect } from '@common/components/Redirect';

import { useAdmissionRouter } from '@admission/routes/Router';
import { Router as useTallyRouter } from '@tally/routes/Router';
import { Router as useHubRouter } from '@hub/routers/Router';
import { Router as useDuesRouter } from '@dues/routes/Router';
import ErrorBoundary from '@common/components/ErrorBoundary';
import { GlobalError } from '@common/pages/GlobalError';
import { maskCpf } from '@libs/utils/masks/cpf';
import useApiLoginEnrichment from '@common/apis/useAPILoginEnrichment';

import constantsLegacyAuth from '@common/constants/legacyAuth';

import { addCookie } from '@libs/utils/helpers/getSafeCookies';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const MonitoringCenter = lazy(() => import('../pages/MonitoringCenter'));
const HelpCenter = lazy(() => import('../pages/HelpCenter'));
const NotFound = lazy(() => import('../pages/NotFound'));

type LocationStateProps = {
  document: string;
  authOrigin: 'sign-in' | 'sign-up' | 'pin';
};

const Router: React.FC = () => {
  const { apiLoginEnrichment } = useApiLoginEnrichment();
  const { admissionPrivatePages, admissionPublicPages } = useAdmissionRouter();
  const { isAuthenticated, user } = useUserContext();
  const pagesDues = useDuesRouter();
  const pagesTally = useTallyRouter();
  const pagesHub = useHubRouter();

  const location = useLocation();

  const { pageViewEvent, userAuthenticatedEvent } = useTracking();

  useEffect(() => {
    window?.scrollTo(0, 0);
    delay(150).then(() => pageViewEvent());
  }, [location]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const { document, authOrigin } =
        (location.state as LocationStateProps) || {
          document: maskCpf(user.document),
          authOrigin: 'cached-sign-in',
        };

      window.history.replaceState({}, '');
      apiLoginEnrichment.send();
      userAuthenticatedEvent({
        authenticationType: authOrigin,
        customerId: document,
      });

      const expireDays = new Date();
      expireDays.setDate(expireDays.getDate() + 120);

      addCookie({
        key: constantsLegacyAuth.cached_sign_in,
        value: user,
        expires: expireDays,
        encrypt: true,
      });
    }
  }, [isAuthenticated, user]);

  useDatadogRum({
    applicationId: import.meta.env.VITE_DD_RUM_APP_ID,
    clientToken: import.meta.env.VITE_DD_RUM_TOKEN,
    version: import.meta.env.VITE_DEPLOY_VERSION,
    service: 'app.consumidorpositivo.com.br',
    sessionSampleRate: 1,
    sessionReplaySampleRate: 1,
  });

  return (
    <ErrorBoundary fallback={<GlobalError />}>
      <Suspense fallback={<LoaderCircular fullPage />}>
        <Routes>
          <Route element={<PublicRoutes />}>
            {admissionPublicPages}
            <Route
              path={RouteMap.Help}
              element={
                isCP ? (
                  <Redirect to="https://ajuda.consumidorpositivo.com.br" />
                ) : (
                  <HelpCenter />
                )
              }
            />
          </Route>
          <Route element={<PrivateRoutes />}>
            {admissionPrivatePages}
            {pagesTally}
            {pagesHub}
            {pagesDues}
            <Route path={RouteMap.Dashboard} element={<Dashboard />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Router;
